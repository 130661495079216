import React, { useEffect, useState } from 'react'
import { postData } from '../../functions/functions';

export const Getsurveys = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  var gid = params.get("gid");
  var cada = params.get("cada");
  var rid = params.get("rid");
  
  useEffect(() => {
    postData(`https://precise.rjsampling.com/invt/accesslink`, {
      gid, cada, rid
    })
    .then(result => {
      console.log(result);
      if(result.success){
        alert(result.message);
        const original_link = result.result[0].entryLink.slice(0, -9);
        window.location.replace(original_link+rid);
      }
    })
  }, [cada, gid, rid])
  return (
    <div style={{fontSize:40, textAlign:"center"}}>Please wait</div>
  )
}
