import './App.css';
import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Switch,
  Route,
  Link,
  BrowserRouter,
  Navigate
} from "react-router-dom";
import { Home } from './pages/home/Home';
import { Getsurveys } from './pages/getsurveys/Getsurveys';
function App() {
  const getQueryParams = (query = null) => (query||window.location.search.replace('?','')).split('&').map(e=>e.split('=').map(decodeURIComponent)).reduce((r,[k,v])=>(r[k]=v,r),{});
  const varib = getQueryParams();
  console.log(varib.surveyNum);
  useEffect(() => {
    fetch(`https://precise.rjsampling.com/lucid/survey/${varib.surveyNum}`)
    .then(res => res.json())
    .then(json => {
      console.log("oye")
      console.log(json)
    }) 
  }, [varib.surveyNum])
  
  return (
    <Routes>
      <Route path="/">
        <Route excact path='/' element={<Home />} />
      </Route>
      <Route path="getsurveys" element={<Getsurveys />}></Route>
    </Routes>
  );
}

export default App;
