import React from 'react'

export const Home = () => {
  return (
    <div className="App">
      <div class="logo-container">
  <ul>
    {/* <li>
      <div class="logo-holder logo-1">
        <a href="">
          <h3>Lorem Ipsum</h3>
          <p>dolor sit amet</p>
        </a>
      </div>
    </li> */}
    {/* <li>
      <div class="logo-holder logo-2">
        <a href="">
          <h3>Lorem</h3>
          <p>ipsum dolor sit</p>
        </a>
      </div>
    </li> */}
    {/* <li>
      <div class="logo-holder logo-3">
        <a href="">
          <h3>Lorem Ipsum</h3>
          <p>dolor sit amet</p>
        </a>
      </div>
    </li> */}
    {/* <li>
      <div class="logo-holder logo-4">
        <a href="">
          <h3>Lorem</h3>
          <p>ipsum dolor sit</p>
        </a>
      </div>
    </li> */}
    {/* <li>
      <div class="logo-holder logo-5">
        <a href="">
          <h3>Lorem</h3>
          <p>ipsum dolor sit</p>
        </a>
      </div>
    </li> */}
    <li>
      <div className="logo-holder logo-6">
        <a href="">
          <h3>Precise <span>Hasoffers</span></h3>
        </a>
      </div>
    </li>
    {/* <li>
      <div class="logo-holder logo-7">
        <a href="">
          <i class="fas fa-book-open"></i>
          <div class="left">
            <h3>Lorem ipsum</h3>
            <p>Dolor sit amet...!</p>
          </div>
        </a>
      </div>
    </li> */}
    {/* <li>
      <div class="logo-holder logo-8">
        <a href="">
          <h3>Lorem <span>Lorem</span></h3>
        </a>
      </div>
    </li> */}
    {/* <li>
      <div class="logo-holder logo-9">
        <a href="">
          <span><i class="fas fa-bell"></i></span>
          <h3>Lorem</h3>
        </a>
      </div>
    </li>     */}
    {/* <li>
      <div class="logo-holder logo-10">
        <a href="">
          <h3>Loremipsum</h3>
          <p>&nbsp;</p>
        </a>
      </div>
    </li> */}
  </ul>
</div>
    </div>
  )
}
